import React from 'react';
import { BenefitsType } from '../../../../../../types/benefitsType';
import { MembershipBenefitReference } from '../../../../../../types/membershipBenefitReferenceType';
import BenefitCard from '../../../../components/BenefitCard/BenefitCard';
import * as S from './Styles';

interface Props {
  benefitItems: Record<string, BenefitsType> | BenefitsType[] | MembershipBenefitReference[];
}

const BenefitsList = ({ benefitItems }: Props) => {
  const benefits = !(benefitItems instanceof Array) ? Object.values(benefitItems) : benefitItems;

  // If preview is true, it should not be listed but reachable with url.
  const visibleBenefits = benefits.filter((benefitItem) => !benefitItem.preview);
  const displayedBenefits = visibleBenefits.slice(0, 3);

  return (
    displayedBenefits && (
      <S.ScrollableWrapper>
        {displayedBenefits.map((benefitItem) => (
          <S.CardWrapper key={benefitItem.slug}>
            <BenefitCard isFlexible benefit={benefitItem} key={`s-${benefitItem.slug}`} />
          </S.CardWrapper>
        ))}
      </S.ScrollableWrapper>
    )
  );
};

export default BenefitsList;

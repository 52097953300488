import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

export const ScrollableWrapper = styled.div`
  display: flex;
  gap: ${spacing.space24};
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow-x: visible;

  @media (max-width: ${breakpoints.m}) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    gap: ${spacing.space16};
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  scroll-snap-align: start;
  margin-top: ${spacing.space12};
  margin-bottom: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    width: 302px;
  }
`;
